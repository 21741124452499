import { Translation, TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { Observable } from 'rxjs';

/**
 * Locales factory.
 *
 * @param translocoService Transloco service.
 */
export function localesFactory(translocoService: TranslocoService): () => Observable<Translation> {
	const defaultLang = translocoService._completeScopeWithLang(translocoService.getDefaultLang());

	let browserLang = getBrowserLang();
	let browserLangObservable: Observable<Translation>;

	if (!['de', 'en'].includes(browserLang ?? '')) {
		browserLang = defaultLang;
	}

	if (browserLang) {
		browserLang = translocoService._completeScopeWithLang(browserLang);
		browserLangObservable = translocoService.load(browserLang);
		translocoService.setActiveLang(browserLang);
	}

	return () => browserLangObservable;
}
