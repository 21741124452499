import { __awaiter, __generator } from "tslib";
import fetch from "isomorphic-unfetch";
export function validatePushApiKey(_a) {
    var _b = _a.endpoint, endpoint = _b === void 0 ? "https://push.appsignal.com" : _b, apiKey = _a.apiKey;
    return __awaiter(this, void 0, void 0, function () {
        var status;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4, fetch("".concat(endpoint, "/1/auth?api_key=").concat(apiKey))];
                case 1:
                    status = (_c.sent()).status;
                    switch (status) {
                        case 200:
                            return [2, true];
                        case 401:
                            return [2, false];
                        default:
                            throw new Error("Invalid ".concat(status, " response from server when authenticating"));
                    }
                    return [2];
            }
        });
    });
}
