import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { map } from 'rxjs';

import { UserPermissionsStore } from '@sulser-print/services/user-permissions-store';
import { UserPermissionsService } from '@sulser-print/services/user-permissions.service';

export const userPermissionsGuard: CanMatchFn = (route) => {
	const userPermissionsStore = inject(UserPermissionsStore);

	if (!userPermissionsStore.arePermissionsAvailable()) {
		const userPermissionsService = inject(UserPermissionsService);
		return userPermissionsService.getUserPermissions().pipe(
			map(() => {
				return userPermissionsStore.hasPermission(route.data?.['permission']);
			}),
		);
	}

	return userPermissionsStore.hasPermission(route.data?.['permission']);
};
