import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, isDevMode } from '@angular/core';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { createErrorHandlerFactory } from '@appsignal/angular';
import Appsignal from '@appsignal/javascript';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';

import { authInterceptor } from '@sulser-print/interceptors/auth.interceptor';
import { notificationInterceptor } from '@sulser-print/interceptors/notification.interceptor';
import { urlInterceptor } from '@sulser-print/interceptors/url.interceptor';
import { NotificationService } from '@sulser-print/services/notification.service';
import { SessionStorageStore } from '@sulser-print/services/session-storage.store';
import { StylesService } from '@sulser-print/services/styles-service';
import { TenantsService } from '@sulser-print/services/tenants.service';
import { TenantsStore } from '@sulser-print/services/tenants.store';
import { provideEnvironment } from '@sulser-print/tokens/environment.token';
import { provideIconNames } from '@sulser-print/tokens/icon-names.token';
import { getDesignSystemIconUrl } from '@sulser-print/utils/icon.utils';

import environment from '../environments/environment';

import { appRoutes } from './app-routes';
import { initializeApplication } from './app.initializer';
import { MobileNotificationService } from './services/notification.service';
import { localesFactory } from './shared/factories/locales.factory';
import { TranslocoHttpLoader } from './shared/services/transloco-loader';

const appsignal = new Appsignal({ key: environment.appSignalKey });

export const applicationConfig: ApplicationConfig = {
	providers: [
		provideEnvironment({
			api: environment.api,
			apiVersion: environment.apiVersion,
			currentEnvironment: environment.currentEnvironment,
			publicApiVersion: environment.publicApiVersion,
		}),
		TenantsStore,
		TranslocoService,
		provideTransloco({
			config: {
				availableLangs: ['en', 'de'],
				defaultLang: 'de',
				failedRetries: 1,
				fallbackLang: 'de',
				missingHandler: {
					logMissingKey: true,
					useFallbackTranslation: true,
				},
				prodMode: !isDevMode(),
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
			},
			loader: TranslocoHttpLoader,
		}),
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.google.clientId, {
							oneTapEnabled: false,
							prompt: 'none',
						}),
					},
					{
						id: MicrosoftLoginProvider.PROVIDER_ID,
						provider: new MicrosoftLoginProvider(environment.microsoft.clientId, {
							prompt: 'login',
						}),
					},
				],
			} as SocialAuthServiceConfig,
		},

		{
			deps: [TranslocoService],
			multi: true,
			provide: APP_INITIALIZER,
			useFactory: localesFactory,
		},
		{
			deps: [TenantsService, StylesService],
			multi: true,
			provide: APP_INITIALIZER,
			useFactory: initializeApplication,
		},
		SessionStorageStore,
		StylesService,
		provideIconNames({
			invisibleIcon: getDesignSystemIconUrl('eye-close'),
			visibleIcon: getDesignSystemIconUrl('eye-open'),
		}),
		provideHttpClient(withInterceptors([urlInterceptor, authInterceptor, notificationInterceptor])),
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		provideIonicAngular(),
		provideRouter(appRoutes),
		{ provide: NotificationService, useClass: MobileNotificationService },
		provideHttpClient(),
		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:30000',
		}),
		{ provide: ErrorHandler, useFactory: createErrorHandlerFactory(appsignal) },
	],
};
