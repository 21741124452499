export function toHashMapString(obj) {
    if (!obj)
        return;
    Object.keys(obj).forEach(function (k) {
        if (typeof obj[k] === "object") {
            obj[k] = JSON.stringify(obj[k]);
        }
        obj[k] = String(obj[k]);
    });
    return obj;
}
export function toHashMap(obj) {
    if (!obj)
        return;
    Object.keys(obj).forEach(function (k) {
        if (typeof obj[k] === "string" ||
            typeof obj[k] === "boolean" ||
            typeof obj[k] === "number") {
            return;
        }
        obj[k] = JSON.stringify(obj[k]);
    });
    return obj;
}
