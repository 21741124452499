import { Routes } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';
import { UserRolesPermissions } from '@sulser-print/constants/permissions/user-role-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';

export const settingsUserRoleRoutes: Routes = [
	{
		loadComponent: () => import('../feature/list/user-roles-list.page').then((p) => p.UserRolesListPage),
		path: '',
		pathMatch: 'full',
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: UserRolesPermissions.READ_USER_ROLE_PERMISSIONS_PERMISSION },
		loadComponent: () => import('../feature/permissions/user-role-permissions.page').then((p) => p.UserRolePermissionsPage),
		path: `:${ApiParameter.USER_ROLE_ID}/:${ApiParameter.USER_ROLE_NAME}`,
	},
];
