import { Routes } from '@angular/router';

import { ProjectsPermissions } from '@sulser-print/constants/permissions/projects-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';

import { AppRoute } from '../../../../shared/constants/app.route.constants';
import { projectAdminGuard } from '../../../../shared/guards/project-admin.guard/project-admin.guard';
import { projectPrintJobsRoutes } from '../feature/print-jobs/routes/print-jobs.routes';

export const projectRoutes: Routes = [
	{
		canMatch: [userPermissionsGuard],
		data: { permission: ProjectsPermissions.READ_PROJECT_FOLDERS_PERMISSION },
		loadComponent: () =>
			import('../feature/directories/feature/directories-list/directories-list.page').then((dl) => dl.DirectoriesListPage),
		path: AppRoute.DIRECTORIES,
	},
	{
		loadComponent: () => import('../feature/file-history/file-history.page').then((dfh) => dfh.FileHistoryPage),
		path: AppRoute.FILE_HISTORY,
	},
	{
		canMatch: [userPermissionsGuard],
		children: projectPrintJobsRoutes,
		data: { permission: ProjectsPermissions.SHOW_PROJECT_PRINT_JOBS_PERMISSION },
		path: AppRoute.PRINT_JOBS,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: ProjectsPermissions.SHOW_PROJECT_USERS_PERMISSION },
		loadComponent: () => import('../feature/users/project-users.page').then((pu) => pu.ProjectUsersPage),
		path: AppRoute.USERS,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: ProjectsPermissions.SHOW_PROJECT_LOGS_PERMISSION },
		loadComponent: () => import('../feature/logs/project-logs.page').then((pu) => pu.ProjectLogsPage),
		path: AppRoute.LOGS,
	},
	// {
	// 	children: fileViewerRoutes,
	// 	path: AppRoute.FILE_VIEWER,
	// },
	{
		canMatch: [userPermissionsGuard],
		data: { permission: ProjectsPermissions.SHOW_PROJECT_ARCHIVE_PERMISSION },
		loadComponent: () => import('../feature/archive-list/project-archive-list.page').then((pa) => pa.ProjectArchiveListPage),
		path: AppRoute.ARCHIVED,
	},
	{
		loadComponent: () => import('../feature/send-data/send-data.page').then((dt) => dt.SendDataPage),
		path: AppRoute.SEND_DATA,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: ProjectsPermissions.UPDATE_PROJECT_PERMISSION },
		loadComponent: () => import('../feature/edit/edit-project.page').then((cep) => cep.EditProjectPage),
		path: AppRoute.EDIT,
	},
	{
		canMatch: [projectAdminGuard],
		loadComponent: () => import('../feature/api-keys/project-api-keys.page').then((ak) => ak.ProjectAPIKeysPage),
		path: AppRoute.API_KEYS,
	},
	{
		path: '**',
		redirectTo: AppRoute.DIRECTORIES,
	},
];
