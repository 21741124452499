import { Routes } from '@angular/router';

import { authenticationGuard } from '@sulser-print/interceptors/authentication.guard';
import { authorizationGuard } from '@sulser-print/interceptors/authorization.guard';

import authRoutes from './auth/routes/auth.routes';
import projectsRoutes from './projects/routes/projects.routes';
import { settingsRoutes } from './settings/routes/settings.routes';
import { AppRoute } from './shared/constants/app.route.constants';

export const appRoutes: Routes = [
	{
		canMatch: [authenticationGuard],
		children: authRoutes,
		path: AppRoute.AUTH,
	},
	{
		canMatch: [authorizationGuard],
		children: [
			{
				children: projectsRoutes,
				path: AppRoute.PROJECTS,
			},
			{
				children: settingsRoutes,
				path: AppRoute.SETTINGS,
			},
			{
				path: '**',
				redirectTo: AppRoute.PROJECTS,
			},
		],
		path: '',
	},
	{
		path: '**',
		redirectTo: AppRoute.PROJECTS,
	},
];
