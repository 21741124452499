import { __awaiter, __generator } from "tslib";
import { urlEncode } from "@appsignal/core";
import { Environment } from "./environment";
import { XDomainTransport } from "./transports/xdomain";
import { XHRTransport } from "./transports/xhr";
import { FetchTransport } from "./transports/fetch";
import { NodeTransport } from "./transports/node";
var PushApi = (function () {
    function PushApi(options) {
        this._uri = options.uri || "https://appsignal-endpoint.net/collect";
        this._apiKey = options.key;
        this._clientVersion = options.version;
        this._transport = this._createTransport(this._url());
    }
    PushApi.prototype.push = function (span) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this._transport.send(span.toJSON())];
                    case 1:
                        _a.sent();
                        return [2, span];
                }
            });
        });
    };
    PushApi.prototype._createTransport = function (url) {
        switch (Environment.transport()) {
            case "XDomainRequest":
                return new XDomainTransport(url);
            case "XMLHttpRequest":
                return new XHRTransport(url);
            case "NodeHTTP":
                return new NodeTransport(url);
            default:
                return new FetchTransport(url);
        }
    };
    PushApi.prototype._url = function () {
        var auth = this._authorization();
        return "".concat(this._uri, "?").concat(urlEncode(auth));
    };
    PushApi.prototype._authorization = function () {
        return {
            api_key: this._apiKey,
            version: this._clientVersion
        };
    };
    return PushApi;
}());
export { PushApi };
