import { __assign, __awaiter, __generator, __values } from "tslib";
import { getGlobalObject } from "@appsignal/core";
var Dispatcher = (function () {
    function Dispatcher(queue, api, options) {
        this._retries = 0;
        this._timerID = 0;
        this._duration = 0;
        this._api = api;
        this._queue = queue;
        this.options = __assign({ limit: 5, initialDuration: 1000 }, options);
        this.reset();
    }
    Dispatcher.prototype.schedule = function (time) {
        var _this = this;
        if (time === void 0) { time = this._duration; }
        var globals = getGlobalObject();
        var BACKOFF_FACTOR = 1.3;
        var cb = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, span, e_1, expDuration, e_2_1;
            var e_2, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 7, 8, 9]);
                        _a = __values(this._queue.drain()), _b = _a.next();
                        _d.label = 1;
                    case 1:
                        if (!!_b.done) return [3, 6];
                        span = _b.value;
                        if (!span)
                            return [2];
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 4, , 5]);
                        return [4, this._api.push(span)];
                    case 3:
                        _d.sent();
                        return [3, 5];
                    case 4:
                        e_1 = _d.sent();
                        expDuration = Math.floor(Math.pow(time, BACKOFF_FACTOR));
                        this._retries = this._retries - 1;
                        if (this._retries === 0) {
                            this.reset();
                        }
                        else {
                            this._queue.push(span);
                            this._timerID = this.schedule(expDuration);
                        }
                        return [2];
                    case 5:
                        _b = _a.next();
                        return [3, 1];
                    case 6: return [3, 9];
                    case 7:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 9:
                        this.reset();
                        return [2];
                }
            });
        }); };
        return globals.setTimeout(cb, time);
    };
    Dispatcher.prototype.reset = function () {
        var _a = this.options, limit = _a.limit, initialDuration = _a.initialDuration;
        this._retries = limit;
        this._duration = initialDuration;
    };
    return Dispatcher;
}());
export { Dispatcher };
