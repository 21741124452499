var XHRTransport = (function () {
    function XHRTransport(url) {
        this.url = url;
    }
    XHRTransport.prototype.send = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                var req_1 = new XMLHttpRequest();
                req_1.onreadystatechange = function () {
                    if (req_1.readyState === XMLHttpRequest.DONE) {
                        resolve({});
                    }
                };
                req_1.open("POST", _this.url);
                req_1.send(data);
            }
            catch (e) {
                reject(e);
            }
        });
    };
    return XHRTransport;
}());
export { XHRTransport };
