import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { TokenService } from '@sulser-print/services/token.service';

import { ProjectPage } from '../../../projects/feature/project/project.page';

export const projectAdminGuard: CanMatchFn = () => {
	const tokenService = inject(TokenService);
	const projectPage = inject(ProjectPage, { optional: true });

	return tokenService.isAdminUser() || !!projectPage?.activeProject()?.isProjectAdmin || tokenService.isCompanyAdminUser();
};
