import { Routes } from '@angular/router';

import { SettingsPermission } from '@sulser-print/constants/permissions/settings-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';

import { AppRoute } from '../../shared/constants/app.route.constants';
import { settingsAdvertisementsRoutes } from '../feature/advertisements/routes/advertisements.routes';
import { settingsReportsRoutes } from '../feature/reports/routes/reports.routes';
import { settingsUserRoleRoutes } from '../feature/user-roles/routes/user-role.routes';
import { settingsArchivedProjectsRoutes } from '../feature/users/feature/archived-projects/routes/archived-projects.routes';
import { settingsUsersRoutes } from '../feature/users/routes/users.routes';

export const settingsRoutes: Routes = [
	{
		canMatch: [userPermissionsGuard],
		data: { permission: SettingsPermission.READ_PROFILE_PERMISSION },
		loadComponent: () => import('../feature/profile/settings-profile.page').then((m) => m.SettingsProfilePage),
		path: AppRoute.PROFILE,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: SettingsPermission.UPDATE_PROFILE_PERMISSION },
		loadComponent: () => import('../feature/access/settings-access.page').then((m) => m.SettingsAccessPage),
		path: AppRoute.ACCESS,
	},
	{
		canMatch: [userPermissionsGuard],
		children: settingsUserRoleRoutes,
		data: { permission: SettingsPermission.READ_USER_ROLES_PERMISSION },
		path: AppRoute.USER_ROLES,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: SettingsPermission.READ_COMPANIES_PERMISSION },
		loadComponent: () => import('../feature/companies/settings-companies-list.page').then((m) => m.SettingsCompaniesListPage),
		path: AppRoute.COMPANIES,
	},
	{
		canMatch: [userPermissionsGuard],
		children: settingsUsersRoutes,
		data: { permission: SettingsPermission.READ_USERS_PERMISSION },
		path: AppRoute.USERS,
	},
	{
		canMatch: [userPermissionsGuard],
		children: settingsArchivedProjectsRoutes,
		data: { permission: SettingsPermission.READ_ARCHIVED_PROJECTS_PERMISSION },
		path: AppRoute.ARCHIVED_PROJECTS,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: SettingsPermission.READ_PRINT_JOBS_PERMISSION },
		loadComponent: () => import('../feature/print-jobs/settings-print-jobs-list.page').then((m) => m.SettingsPrintJobsListPage),
		path: AppRoute.PRINT_JOBS,
	},
	{
		canMatch: [userPermissionsGuard],
		children: settingsAdvertisementsRoutes,
		data: { permission: SettingsPermission.READ_ADVERTISEMENTS_PERMISSION },
		path: AppRoute.ADVERTISEMENTS,
	},
	{
		canMatch: [userPermissionsGuard],
		children: settingsReportsRoutes,
		data: { permission: SettingsPermission.READ_REPORTS_PERMISSION },
		path: AppRoute.REPORTS,
	},
];
