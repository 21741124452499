import { __assign, __extends } from "tslib";
import { Serializable, getStacktrace, toHashMapString, isError } from "@appsignal/core";
var Span = (function (_super) {
    __extends(Span, _super);
    function Span(span) {
        return _super.call(this, __assign({ timestamp: Math.round(new Date().getTime() / 1000), namespace: "frontend", error: {
                name: "NullError",
                message: "No error has been set",
                backtrace: []
            } }, span)) || this;
    }
    Span.prototype.setAction = function (name) {
        if (!name || typeof name !== "string") {
            return this;
        }
        this._data.action = name;
        return this;
    };
    Span.prototype.setNamespace = function (name) {
        if (!name || typeof name !== "string") {
            return this;
        }
        this._data.namespace = name;
        return this;
    };
    Span.prototype.setError = function (error) {
        if (!error || !isError(error))
            return this;
        this._data.error = {
            name: error.name || "[unknown]",
            message: error.message,
            backtrace: getStacktrace(error)
        };
        return this;
    };
    Span.prototype.setTags = function (tags) {
        this._data.tags = __assign(__assign({}, this._data.tags), toHashMapString(tags));
        return this;
    };
    Span.prototype.setParams = function (params) {
        this._data.params = __assign(__assign({}, this._data.params), params);
        return this;
    };
    Span.prototype.setBreadcrumbs = function (breadcrumbs) {
        this._data.breadcrumbs = breadcrumbs;
        return this;
    };
    return Span;
}(Serializable));
export { Span };
