import { __awaiter, __generator } from "tslib";
var FetchTransport = (function () {
    function FetchTransport(url, headers) {
        this.url = url;
    }
    FetchTransport.prototype.send = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var res, statusText, ok;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, fetch(this.url, {
                            method: "POST",
                            body: data
                        })];
                    case 1:
                        res = _a.sent();
                        statusText = res.statusText, ok = res.ok;
                        return [2, ok ? Promise.resolve({}) : Promise.reject({ statusText: statusText })];
                }
            });
        });
    };
    return FetchTransport;
}());
export { FetchTransport };
