import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { ToastController } from '@ionic/angular/standalone';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { NotificationService } from '@sulser-print/services/notification.service';

export class MobileNotificationService extends NotificationService {
	localesService = inject(TranslocoService);

	toastController = inject(ToastController);

	override showErrorNotification(error: HttpErrorResponse): this {
		console.log(error);
		const toast = this.toastController.create({
			color: 'danger',
			duration: 3000,
			message: this.localesService.translate(error.error.code, error.error.messageData) ?? error.error.message ?? 'Unknown error',
		});

		console.warn(error);

		fromPromise(toast)
			.pipe(tap((toast) => toast.present()))
			.subscribe();

		return this;
	}

	override showNotification<T>(response: HttpResponse<T>): this {
		const { body } = response;

		if (!body || typeof body !== 'object') {
			return this;
		}

		let message = '';

		if ('code' in body && typeof body.code === 'string') {
			message =
				'messageData' in body && typeof body.messageData === 'object' && body.messageData !== null
					? this.localesService.translate(body.code, body.messageData)
					: this.localesService.translate(body.code);
		} else if ('message' in body && typeof body.message === 'string') {
			message = body.message;
		}

		if (!message?.trim()?.length) {
			return this;
		}

		const toast = this.toastController.create({
			color: 'success' in body && body.success ? 'success' : 'danger',
			duration: 3000,
			message,
		});

		fromPromise(toast)
			.pipe(tap((toast) => toast.present()))
			.subscribe();

		return this;
	}
}
