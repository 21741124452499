import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, mergeMap, switchMap, throwError } from 'rxjs';

import { AuthService } from '@sulser-print/services/auth.service';
import { TokenService } from '@sulser-print/services/token.service';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
	const tokenService = inject(TokenService);
	const authService = inject(AuthService);

	const authHeader = request.headers.get('Authorization');
	const token = tokenService.getToken();
	const refreshToken = tokenService.getRefreshToken();

	let requestToIntercept = request;
	if (!authHeader || ![refreshToken, token].includes(authHeader)) {
		requestToIntercept = request.clone({
			setHeaders: { Authorization: token },
		});
	}

	return next(requestToIntercept).pipe(
		catchError((response) => {
			if (
				response.error?.code === 'auth-e-001' ||
				(token && response.error?.code === 'auth-e-013') ||
				response.error?.code === 'auth-e-022'
			) {
				return authService.logout().pipe(mergeMap(() => next(request)));
			}

			if (tokenService.getToken() && response.error?.code === 'auth-e-014') {
				return authService.refreshToken(tokenService.getRefreshToken()).pipe(switchMap(() => authInterceptor(request, next)));
			}

			return throwError(() => response);
		}),
	);
};
