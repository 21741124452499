import { Injectable } from '@angular/core';

/**
 * Session storage key.
 */
export enum SessionStorageKey {
	SIDEBAR_EXPANDED = 'sidebar_expanded',
	TREE_EXPANDED = 'tree_expanded',
}

/**
 * Session storage value.
 */
interface SessionStorageValue {
	[SessionStorageKey.SIDEBAR_EXPANDED]: 'false' | 'true';
	[SessionStorageKey.TREE_EXPANDED]: 'false' | 'true';
}

/**
 * Session storage service.
 */
@Injectable({ providedIn: 'any' })
export class SessionStorageStore {
	/**
	 * Clear session storage.
	 */
	clear(): this {
		sessionStorage.clear();

		return this;
	}

	/**
	 * Get value at key.
	 * @param key - Key to get value at.
	 */
	get<T extends SessionStorageKey>(key: T) {
		const value = sessionStorage.getItem(key) as SessionStorageValue[SessionStorageKey];
		if (value) {
			return JSON.parse(value) as SessionStorageValue[T];
		}
		return undefined;
	}

	/**
	 * Remove item at key.
	 * @param key - Key to remove item at.
	 */
	remove(key: SessionStorageKey): this {
		sessionStorage.removeItem(key);

		return this;
	}

	/**
	 * Set value.
	 * @param key - Key to set value at.
	 * @param value - Value to set.
	 */
	set<T extends SessionStorageKey>(key: T, value: SessionStorageValue[T]): this {
		sessionStorage.setItem(key, JSON.stringify(value));

		return this;
	}
}
