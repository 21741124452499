import { StylesService } from '@sulser-print/services/styles-service';
import { TenantsService } from '@sulser-print/services/tenants.service';
import { firstValueFrom, forkJoin } from 'rxjs';

/**
 * Application initializer.
 *
 * @param tenantsService Tenants service.
 * @param stylesService  Styles service.
 */
export function initializeApplication(tenantsService: TenantsService, stylesService: StylesService) {
	return () => firstValueFrom(forkJoin([stylesService.initTheme(), tenantsService.initTenant()]));
}
