import { Routes } from '@angular/router';

import { AppRoute } from '../../../../../../shared/constants/app.route.constants';
import { printJobsOrdersListRoutes } from '../feature/orders/routes/orders-list.routes';

export const projectPrintJobsRoutes: Routes = [
	{
		loadComponent: () =>
			import('../feature/order/project-print-jobs-order-page.component').then((pjl) => pjl.ProjectPrintJobsOrderPage),
		path: '',
		pathMatch: 'full',
	},
	{
		children: printJobsOrdersListRoutes,
		path: AppRoute.ORDERS_LIST,
	},
];
