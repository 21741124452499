import { getGlobalObject, isNodeEnv } from "@appsignal/core";
var Environment = (function () {
    function Environment() {
    }
    Environment.serialize = function () {
        return {
            transport: this.transport(),
            origin: this.origin()
        };
    };
    Environment.origin = function () {
        var globals = getGlobalObject();
        if (!globals.location) {
            return "";
        }
        return (globals.location.origin ||
            "".concat(globals.location.protocol, "//").concat(globals.location.hostname));
    };
    Environment.transport = function () {
        var globals = getGlobalObject();
        if (isNodeEnv() && typeof jest === "undefined") {
            return "NodeHTTP";
        }
        else if (globals.XDomainRequest) {
            return "XDomainRequest";
        }
        else if (globals.XMLHttpRequest && !globals.fetch) {
            return "XMLHttpRequest";
        }
        else {
            return "fetch";
        }
    };
    Environment.supportsPromises = function () {
        var globals = getGlobalObject();
        return ("Promise" in globals &&
            "resolve" in globals.Promise &&
            "reject" in globals.Promise &&
            "all" in globals.Promise &&
            "race" in globals.Promise &&
            (function () {
                var resolve;
                new globals.Promise(function (r) {
                    resolve = r;
                });
                return typeof resolve === "function";
            })());
    };
    return Environment;
}());
export { Environment };
