import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { compose, toHashMap } from "@appsignal/core";
import { VERSION } from "./version";
import { PushApi } from "./api";
import { Environment } from "./environment";
import { Span } from "./span";
import { Queue } from "./queue";
import { Dispatcher } from "./dispatcher";
var Appsignal = (function () {
    function Appsignal(options) {
        this.VERSION = VERSION;
        this.ignored = [];
        this._breadcrumbs = [];
        this._hooks = {
            decorators: Array(),
            overrides: Array()
        };
        this._env = Environment.serialize();
        this._queue = new Queue([]);
        var _a = options.key, key = _a === void 0 ? "" : _a, uri = options.uri, revision = options.revision, ignoreErrors = options.ignoreErrors;
        if (revision && typeof revision !== "string") {
            options.revision = String(revision);
        }
        if (key === "") {
            console.info("[APPSIGNAL]: Started in development mode.");
        }
        this._api = new PushApi({
            key: key,
            uri: uri,
            version: this.VERSION
        });
        if (ignoreErrors && Array.isArray(ignoreErrors)) {
            this.ignored = ignoreErrors;
        }
        this._dispatcher = new Dispatcher(this._queue, this._api);
        this._options = options;
    }
    Appsignal.prototype.send = function (data, tagsOrFn, namespace) {
        var _this = this;
        if (!(data instanceof Error) && !(data instanceof Span)) {
            console.error("[APPSIGNAL]: Can't send error, given error is not a valid type");
            return;
        }
        if (this.ignored.length !== 0) {
            if (data instanceof Error &&
                this.ignored.some(function (el) { return el.test(data.message); })) {
                console.warn("[APPSIGNAL]: Ignored an error: ".concat(data.message));
                return;
            }
            if (data instanceof Span) {
                var error_1 = data.serialize().error;
                if (error_1.message && this.ignored.some(function (el) { return el.test(error_1.message); })) {
                    console.warn("[APPSIGNAL]: Ignored a span: ".concat(error_1.message));
                    return;
                }
            }
        }
        var span = data instanceof Span ? data : this._createSpanFromError(data);
        if (this._hooks.decorators.length > 0) {
            compose.apply(void 0, __spreadArray([], __read(this._hooks.decorators), false))(span);
        }
        if (tagsOrFn) {
            if (typeof tagsOrFn === "function") {
                var callback = tagsOrFn;
                callback(span);
            }
            else {
                console.warn("[APPSIGNAL]: DEPRECATED: Calling the `send`/`sendError` function with a tags object is deprecated. Use the callback argument instead.");
                var tags = (toHashMap(tagsOrFn) || {});
                span.setTags(tags);
            }
        }
        if (namespace) {
            console.warn("[APPSIGNAL]: DEPRECATED: Calling the `send`/`sendError` function with a namespace is deprecated. Use the callback argument instead.");
            span.setNamespace(namespace);
        }
        if (this._breadcrumbs.length > 0)
            span.setBreadcrumbs(this._breadcrumbs);
        if (this._hooks.overrides.length > 0) {
            compose.apply(void 0, __spreadArray([], __read(this._hooks.overrides), false))(span);
        }
        if (Environment.supportsPromises()) {
            this._breadcrumbs = [];
            if (!this._options.key) {
                console.warn("[APPSIGNAL]: Span not sent because we're in development mode:", span);
                if (data instanceof Error) {
                    throw data;
                }
            }
            else {
                return this._api.push(span).catch(function () {
                    _this._queue.push(span);
                    setTimeout(function () { return _this._dispatcher.schedule(); }, 0);
                });
            }
        }
        else {
            console.error("[APPSIGNAL]: Error not sent. A Promise polyfill is required.");
            return;
        }
    };
    Appsignal.prototype.sendError = function (error, tagsOrFn, namespace) {
        return this.send(error, tagsOrFn, namespace);
    };
    Appsignal.prototype.use = function (plugin) {
        plugin.call(this);
    };
    Appsignal.prototype.createSpan = function (fn) {
        var _a = this._options, _b = _a.revision, revision = _b === void 0 ? "" : _b, namespace = _a.namespace;
        var span = new Span({
            environment: this._env,
            revision: revision
        });
        if (namespace)
            span.setNamespace(namespace);
        if (fn && typeof fn === "function")
            fn(span);
        return span;
    };
    Appsignal.prototype.wrap = function (fn, tagsOrFn, namespace) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        return [4, fn()];
                    case 1: return [2, _a.sent()];
                    case 2:
                        e_1 = _a.sent();
                        return [4, this.sendError(e_1, tagsOrFn, namespace)];
                    case 3:
                        _a.sent();
                        return [2, Promise.reject(e_1)];
                    case 4: return [2];
                }
            });
        });
    };
    Appsignal.prototype.addDecorator = function (decorator) {
        this._hooks.decorators.push(decorator);
    };
    Appsignal.prototype.addOverride = function (override) {
        this._hooks.overrides.push(override);
    };
    Appsignal.prototype.demo = function () {
        var span = this._createSpanFromError(new Error("Hello world! This is an error used for demonstration purposes."));
        span
            .setAction("TestAction")
            .setParams({
            path: "/hello",
            method: "GET"
        })
            .setTags({
            demo_sample: "true"
        });
        this.send(span);
    };
    Appsignal.prototype.addBreadcrumb = function (breadcrumb) {
        var crumb = __assign(__assign({ timestamp: Math.round(new Date().getTime() / 1000) }, breadcrumb), { metadata: toHashMap(breadcrumb.metadata) });
        if (!crumb.category) {
            console.warn("[APPSIGNAL]: Breadcrumb not added. `category` is missing.");
            return;
        }
        if (!crumb.action) {
            console.warn("[APPSIGNAL]: Breadcrumb not added. `action` is missing.");
            return;
        }
        if (this._breadcrumbs.length === 20) {
            this._breadcrumbs.pop();
        }
        this._breadcrumbs.unshift(crumb);
    };
    Appsignal.prototype._createSpanFromError = function (error) {
        var event = this.createSpan();
        event.setError(error);
        return event;
    };
    return Appsignal;
}());
export default Appsignal;
