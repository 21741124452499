export function isError(error) {
    return (typeof error === "object" && typeof error.message !== "undefined");
}
export function getStacktrace(error) {
    if (typeof error.stacktrace !== "undefined" ||
        typeof error["opera#sourceloc"] !== "undefined") {
        var _a = error.stacktrace, stacktrace = _a === void 0 ? "" : _a;
        return stacktrace
            .split("\n")
            .filter(function (line) { return line !== ""; });
    }
    else if (error.stack) {
        var _b = error.stack, stack = _b === void 0 ? "" : _b;
        return stack.split("\n").filter(function (line) { return line !== ""; });
    }
    else {
        return ["No stacktrace available"];
    }
}
