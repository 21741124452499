var XDomainTransport = (function () {
    function XDomainTransport(url) {
        this.url = url;
    }
    XDomainTransport.prototype.send = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var _a;
            var req = new XDomainRequest();
            var rx = new RegExp("^https?:");
            req.onload = function () { return resolve({}); };
            req.open("POST", _this.url.replace(rx, (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol));
            setTimeout(function () {
                try {
                    req.send(data);
                }
                catch (e) {
                    reject(e);
                }
            }, 0);
        });
    };
    return XDomainTransport;
}());
export { XDomainTransport };
