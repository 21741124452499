import { Routes } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';

import { AppRoute } from '../../../../../../shared/constants/app.route.constants';

export const settingsArchivedProjectsRoutes: Routes = [
	{
		loadComponent: () => import('../list/archived-projects-list.page').then((m) => m.SettingsArchivedProjectsListPage),
		path: '',
		pathMatch: 'full',
	},
	{
		children: [
			{
				loadComponent: () =>
					import('../users/archived-project-users-list.page').then((m) => m.SettingsArchivedProjectUsersListPage),
				path: AppRoute.USERS,
			},
		],
		path: `:${ApiParameter.PROJECT_ID}`,
	},
];
