import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';

import { catchError, map, throwError } from 'rxjs';

import { AuthService } from '@sulser-print/services/auth.service';
import { TokenService } from '@sulser-print/services/token.service';

export const authorizationGuard: CanMatchFn = () => {
	const authService = inject(AuthService);
	const tokenService = inject(TokenService);
	const router = inject(Router);

	if (tokenService.isTokenExpired() && tokenService.getRemember()) {
		return authService.refreshToken(tokenService.getRefreshToken()).pipe(
			catchError(() => {
				router.navigate(['/auth']);
				return throwError(() => false);
			}),
			map((response) => !!response.data?.token),
		);
	}

	if (authService.isAuthenticated()) {
		return true;
	}

	const path = window.location.pathname;
	const query = window.location.search;
	router.navigate(['/auth'], { queryParams: { returnUrl: path + query } });

	return false;
};
