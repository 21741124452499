var Serializable = (function () {
    function Serializable(data) {
        this._data = data;
    }
    Serializable.prototype.toJSON = function () {
        return JSON.stringify(this._data);
    };
    Serializable.prototype.serialize = function () {
        return this._data;
    };
    return Serializable;
}());
export { Serializable };
