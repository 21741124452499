import { Routes } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';
import { AdvertismentsPermissions } from '@sulser-print/constants/permissions/advertisments-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';

import { AppRoute } from '../../../../shared/constants/app.route.constants';

export const settingsAdvertisementsRoutes: Routes = [
	{
		loadComponent: () => import('../feature/list/settings-advertisements-list.page').then((m) => m.SettingsAdvertisementsListPage),
		path: '',
		pathMatch: 'full',
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: AdvertismentsPermissions.CREATE_ADVERTISEMENT_PERMISSION },
		loadComponent: () => import('../feature/form/settings-advertisement-form.page').then((m) => m.SettingsAdvertisementFormPage),
		path: AppRoute.CREATE,
	},
	{
		children: [
			{
				loadComponent: () =>
					import('../feature/preview/settings-advertisement-preview.page').then((m) => m.SettingsAdvertisementPreviewPage),
				path: '',
				pathMatch: 'full',
			},
			{
				canMatch: [userPermissionsGuard],
				data: { permission: AdvertismentsPermissions.UPDATE_ADVERTISEMENT_PERMISSION },
				loadComponent: () =>
					import('../feature/form/settings-advertisement-form.page').then((m) => m.SettingsAdvertisementFormPage),
				path: AppRoute.EDIT,
			},
		],
		path: `:${ApiParameter.ADVERTISEMENT_ID}`,
	},
];
