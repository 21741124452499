import { Routes } from '@angular/router';

import { SettingsPermission } from '@sulser-print/constants/permissions/settings-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';

import { AppRoute } from '../../../../shared/constants/app.route.constants';

export const settingsReportsRoutes: Routes = [
	{
		canActivate: [userPermissionsGuard],
		data: { permission: SettingsPermission.READ_PRINT_JOB_ACTIVITY_REPORT_PERMISSION },
		loadComponent: () => import('../feature/print-jobs/activities-list.page').then((m) => m.SettingsReportsPrintJobsActivitiesListPage),
		path: AppRoute.PRINT_JOBS,
	},
];
