import { Routes } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';
import { UsersPermissions } from '@sulser-print/constants/permissions/users-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';

import { AppRoute } from '../../../../shared/constants/app.route.constants';

export const settingsUsersRoutes: Routes = [
	{
		loadComponent: () => import('../feature/list/settings-users-list.page').then((p) => p.SettingsUsersListPage),
		path: '',
		pathMatch: 'full',
	},
	{
		canMatch: [userPermissionsGuard],
		data: { [AppRoute.EDIT]: false, permission: UsersPermissions.CREATE_USERS_PERMISSION },
		loadComponent: () => import('../feature/create-or-edit/create-or-edit-user.page').then((p) => p.SettingsCreateOrEditUserPage),
		path: AppRoute.CREATE,
	},
	{
		children: [
			{
				loadComponent: () => import('../feature/user-details/user-details.page').then((p) => p.UserDetailsPage),
				path: '',
				pathMatch: 'full',
			},
			{
				data: { [AppRoute.EDIT]: true },
				loadComponent: () =>
					import('../feature/create-or-edit/create-or-edit-user.page').then((p) => p.SettingsCreateOrEditUserPage),
				path: AppRoute.EDIT,
			},
		],
		path: `:${ApiParameter.USER_ID}`,
	},
];
