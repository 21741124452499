import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, inject } from '@angular/core';

import { tap } from 'rxjs';

import { ThemesService } from './themes.service';

@Injectable({
	providedIn: 'root',
})
export class StylesService {
	@Inject(DOCUMENT) private readonly document: Document = inject(DOCUMENT);

	private root: HTMLElement | null;

	private readonly themesService = inject(ThemesService);

	constructor() {
		this.root = this.document.querySelector(':root');
	}

	/**
	 * Get current theme and apply them on the entire application.
	 */
	public initTheme() {
		return this.themesService.getThemes().pipe(
			tap((response) => {
				if (response.data)
					for (const theme of response.data) {
						this.set(theme.key, theme.value);
					}
			}),
		);
	}

	/**
	 * Set style property.
	 *
	 * @param property  Style property.
	 * @param value     Style property value.
	 */
	set(property: string, value: string): void {
		this.root?.style?.setProperty(property, value);
	}
}
