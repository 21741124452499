import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, tap, throwError } from 'rxjs';

import { HeaderParameter } from '@sulser-print/constants/header-parameters';
import { NotificationService } from '@sulser-print/services/notification.service';

export const notificationInterceptor: HttpInterceptorFn = (request, next) => {
	const notificationService = inject(NotificationService);

	return next(request).pipe(
		catchError((response) => {
			if (response instanceof HttpErrorResponse) {
				notificationService.showErrorNotification(response);
			}

			return throwError(() => response);
		}),
		tap((response) => {
			if (
				response instanceof HttpResponse &&
				response.ok &&
				(!['DELETE', 'GET', 'POST', 'PUT'].includes(request.method) || !request.headers.get(HeaderParameter.HIDE_MESSAGE))
			) {
				notificationService.showNotification(response);
			}
		}),
	);
};
