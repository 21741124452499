import { Routes } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';

export const printJobsOrdersListRoutes: Routes = [
	{
		loadComponent: () =>
			import('../feature/list/orders-list/project-print-jobs-orders-list.page').then((pjo) => pjo.ProjectPrintJobsOrdersListPage),
		path: '',
		pathMatch: 'full',
	},
	{
		loadComponent: () =>
			import('../feature/details/project-print-jobs-order-details.page').then((pjd) => pjd.ProjectPrintJobsOrderDetailsPage),
		path: `:${ApiParameter.PRINT_JOB_ID}`,
	},
];
