var NodeTransport = (function () {
    function NodeTransport(url) {
        this.url = url;
        this.https = import("https");
    }
    NodeTransport.prototype.send = function (data) {
        var _this = this;
        var options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Content-Length": data.length
            }
        };
        return new Promise(function (resolve, reject) {
            _this.https
                .then(function (https) {
                var req = https
                    .request(_this.url, options, function () { })
                    .on("error", function (error) { return reject(error); });
                req.write(data);
                req.end();
                resolve({});
            })
                .catch(function (reason) {
                console.warn("NodeTransport is being used, but the HTTPS module could not be imported. No data will be sent to AppSignal.");
                reject(reason);
            });
        });
    };
    return NodeTransport;
}());
export { NodeTransport };
