// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';

export const environment = {
	api: 'https://dev.sulserprint.ch/api',
	apiVersion: 'v1',
	appSignalKey: '0e31d3c6-3dd4-4403-ac54-1a2dcc0f42ef',
	currentEnvironment: 'localhost',
	google: {
		clientId: '334776987098-4ar2rb6s7v9hn0sd6vndas1vdrcfda51.apps.googleusercontent.com',
	},
	microsoft: {
		clientId: '97f1e518-f72b-491c-b528-bd03a1cb887f',
		objectId: '31156e80-962c-499b-8af6-a879246427ca',
		tenantId: '22f8ff1c-7402-4937-a673-f0c3bb936686',
	},
	onlyOffice: {
		platformUrl: 'https://dev.sulserprint.ch/api/p1/only_office',
		serverUrl: 'https://sppoffice.switzerlandnorth.azurecontainer.io',
	},
	pdfTron: {
		licenseKey:
			'Sulser Print AG (hitz.io):PWS:Sulser Print AG::B+2:28332263DD61815C33A681734AA1DD24DD5FB8EFE1C180DB8E418391E7E9CE91A8B630D826BD',
		// serverUrl: 'http://localhost:8090',
		serverUrl: 'https://dev.sulserprint.ch:8443',
	},
	production: false,
	publicApiVersion: 'p1',
} as const;
export default environment; // Included with Angular CLI.
