export function isNodeEnv() {
    return (Object.prototype.toString.call(typeof process !== "undefined" ? process : 0) === "[object process]");
}
export function getGlobalObject() {
    return (isNodeEnv()
        ? global
        : typeof window !== "undefined"
            ? window
            : typeof self !== "undefined"
                ? self
                : {});
}
