import { __generator, __read, __spreadArray } from "tslib";
var Queue = (function () {
    function Queue(data) {
        this._data = data || [];
    }
    Queue.prototype.clear = function () {
        this._data = [];
    };
    Queue.prototype.values = function () {
        return this._data;
    };
    Queue.prototype.push = function (item) {
        var _a;
        return Array.isArray(item)
            ? (_a = this._data).push.apply(_a, __spreadArray([], __read(item), false)) : this._data.push(item);
    };
    Queue.prototype.drain = function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(this._data.length > 0)) return [3, 2];
                    return [4, this._data.shift()];
                case 1:
                    _a.sent();
                    return [3, 0];
                case 2: return [2];
            }
        });
    };
    return Queue;
}());
export { Queue };
