import { Routes } from '@angular/router';

import { AppRoute } from '../../shared/constants/app.route.constants';

export const authRoutes: Routes = [
	{
		children: [
			{
				loadComponent: () => import('../pages/login/login.page').then((c) => c.LoginPage),
				path: AppRoute.LOGIN,
			},
			{
				loadComponent: () => import('../pages/forgot-password/forgot-password.page').then((c) => c.ForgotPasswordPage),
				path: AppRoute.FORGOT_PASSWORD,
			},
			{
				path: '**',
				redirectTo: AppRoute.LOGIN,
			},
		],
		loadComponent: () => import('../pages/auth/auth.page').then((c) => c.AuthPage),
		path: '',
	},
];
export default authRoutes;
