import { DOCUMENT, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { AlertController, IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { TranslocoService } from '@ngneat/transloco';
import { filter } from 'rxjs';

import { DarkModeService } from './services/dark-mode.service';
import { ActionRole } from './shared/constants/action-role';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonApp, IonRouterOutlet, NgClass],
	providers: [DarkModeService],
	selector: 'app-root',
	standalone: true,
	styleUrls: ['./app.component.scss'],
	templateUrl: './app.component.html',
})
export class AppComponent {
	private readonly alertController = inject(AlertController);

	private readonly translocoService = inject(TranslocoService);

	darkModeService = inject(DarkModeService);

	document = inject(DOCUMENT);

	constructor(swUpdate: SwUpdate) {
		effect(() => {
			const darkMode = this.darkModeService.darkMode();
			if (darkMode) {
				this.document.body.classList.add('dark');
			} else {
				this.document.body.classList.remove('dark');
			}
		});

		swUpdate.versionUpdates
			.pipe(filter((event_): event_ is VersionReadyEvent => event_.type === 'VERSION_READY'))
			.subscribe(async () => {
				const alert = await this.onUpdatePrompted();
				await alert.present();
				const { role } = await alert.onDidDismiss();
				if (role === ActionRole.CONFIRM) {
					swUpdate.activateUpdate().then(() => document.location.reload());
				}
			});
	}

	private async onUpdatePrompted() {
		return this.alertController.create({
			buttons: [
				{
					role: ActionRole.CANCEL,
					text: this.translocoService.translate('no'),
				},
				{
					role: ActionRole.CONFIRM,
					text: this.translocoService.translate('yes'),
				},
			],
			header: this.translocoService.translate('update-available'),
			message: this.translocoService.translate('update-available-message'),
		});
	}
}
