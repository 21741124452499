var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ErrorHandler, Injectable } from "@angular/core";
let AppsignalErrorHandler = class AppsignalErrorHandler extends ErrorHandler {
    constructor(appsignal) {
        super();
        this._appsignal = appsignal;
    }
    handleError(error) {
        const span = this._appsignal.createSpan();
        span.setError(error).setTags({ framework: "Angular" });
        this._appsignal.send(span);
        ErrorHandler.prototype.handleError.call(this, error);
    }
};
AppsignalErrorHandler = __decorate([
    Injectable()
], AppsignalErrorHandler);
export { AppsignalErrorHandler };
export function createErrorHandlerFactory(appsignal) {
    return function errorHandlerFactory() {
        return new AppsignalErrorHandler(appsignal);
    };
}
