import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { AuthService } from '@sulser-print/services/auth.service';

export const authenticationGuard: CanMatchFn = () => {
	const authService = inject(AuthService);

	return !authService.isAuthenticated();
};
