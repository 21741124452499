import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { HeaderParameter } from '@sulser-print/constants/header-parameters';
import { getEnvironmentConfig } from '@sulser-print/tokens/environment.token';

function isUrl(value: string): boolean {
	return /^(http|https|ftp|sftp|ws|wss):\/\//.test(value || '');
}

/**
 * Get url.
 *
 * @param request Request.
 * @protected
 */
function getUrl<T>(request: HttpRequest<T>): string {
	const environment = getEnvironmentConfig();
	if (environment && environment.api && !isUrl(request.url)) {
		if (environment.apiVersion) {
			const isRequestPublic = request.headers.get('public') === 'true';
			return `${environment.api}/${isRequestPublic ? environment.publicApiVersion : environment.apiVersion}/${request.url || ''}`;
		}
		return `${environment.api}/${request.url || ''}`;
	}
	return request.url || '';
}

export const urlInterceptor: HttpInterceptorFn = (request_, next) => {
	if (request_.headers.get(HeaderParameter.INTERCEPT) === 'false') {
		return next(request_);
	}

	const translocoService = inject(TranslocoService);

	const request = request_.clone({
		headers: request_.headers.append(HeaderParameter.LANGUAGE, translocoService.getActiveLang()),
		url: getUrl(request_),
	});

	return next(request);
};
