import { Routes } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';
import { ProjectsPermissions } from '@sulser-print/constants/permissions/projects-permissions';
import { userPermissionsGuard } from '@sulser-print/interceptors/user-permissions.guard';
import { ProjectType } from '@sulser-print/models/project/project-type';

import { AppRoute } from '../../shared/constants/app.route.constants';
import { projectRoutes } from '../feature/project/routes/project.routes';

export const projectsRoutes: Routes = [
	{
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: ProjectType.DEFAULT,
			},
			{
				loadComponent: () => import('../feature/projects-list/projects-list.page').then((cmp) => cmp.ProjectsListPage),
				path: `:${ApiParameter.TYPE}`,
			},
		],
		loadComponent: () => import('../feature/projects-list-tabs/projects-list-tabs.page').then((plc) => plc.ProjectsListTabsPage),
		path: AppRoute.LIST,
	},
	{
		canMatch: [userPermissionsGuard],
		data: { permission: ProjectsPermissions.CREATE_PROJECT_PERMISSION },
		loadComponent: () => import('../feature/project-create/project-create.page').then((pf) => pf.ProjectCreatePage),
		path: AppRoute.CREATE,
	},
	{
		children: projectRoutes,
		loadComponent: () => import('../feature/project/project.page').then((p) => p.ProjectPage),
		path: `:${ApiParameter.PROJECT_ID}`,
	},
	{
		path: '**',
		redirectTo: `${AppRoute.LIST}/${ProjectType.DEFAULT}`,
	},
];
export default projectsRoutes;
