import { Injectable } from '@angular/core';

import { ApiAction } from '@sulser-print/constants/api-actions';
import { ApiResourceModule } from '@sulser-print/constants/api-modules/api-resource-modules';
import { HeaderParameter } from '@sulser-print/constants/header-parameters';
import { Response } from '@sulser-print/models/response';
import { Theme } from '@sulser-print/models/theme/theme';

import { RequestService } from './request.service';

@Injectable({ providedIn: 'root' })
export class ThemesService extends RequestService {
	/**
	 * Get themes.
	 */
	getThemes() {
		return this.httpClient.get<Response<Array<Theme>>>(`${ApiResourceModule.THEMES}/${ApiAction.READ}`, {
			headers: { [HeaderParameter.PUBLIC]: 'true' },
		});
	}
}
